import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
// import { GET_VIEWCATEGORYLIST } from '../../actions';
import { Table } from "react-bootstrap"; 
import Moment from 'moment';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const subscriberId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      subscriberId: subscriberId,
      subscriberslistdata:'',
      subscribersingledata:''
    };

    var qs = require('qs');
    var postObject = {             
    id   :subscriberId
    };
    console.log(postObject, 'sub_id');
    axios.post(apiUrl+"subscribers/view",qs.stringify(postObject)).then(res => {
      console.log(res, 'resssssss');
      if(res.data.status === 'success'){
          this.setState({ subscriberslistdata: res.data.subscriberslistview, subscribersingledata: res.data.subscriberslistview[0]});
          console.log(res.data.subscriberslistview[0], 'asfasd')
      }
    }); 
  }

  subscriberData() {
    let catImg = '';
    let preview = '';
      var subscriberslist = this.state.subscriberslistdata;
      if (subscriberslist != "undefined" && subscriberslist != null) {
        console.log(subscriberslist, 'subscriberslist');
      
        if (subscriberslist.length > 0) {
  
          if (Object.keys(subscriberslist).length > 0) {
            const helperlistDetails = subscriberslist.map(
              (subscriberslist, Index) => {
                let sno = Index+1;
                
                var act = "";
                 if(subscriberslist.current_status == 'A'){
                  var activeClass = 'activeClass';
                  act = "(Active)";
                }else{
                  activeClass = 'inactiveClass';
                }
  
                let newDateDisp = Moment(subscriberslist.created_date).format('DD/MM/YYYY hh:mm A');
               
                return (
                  <tr class={activeClass} key={subscriberslist.sub_detail_id}>
                  <td>{sno}</td>
                  <td>{subscriberslist.title + ' ' + act }</td>
                  <td>{subscriberslist.membership_name}</td>
                  <td>${subscriberslist.amount + " / " + (subscriberslist.period ? subscriberslist.period + "ly" : "")}</td>
                  <td>{newDateDisp}</td>
                  </tr>
                );
              }
            );
            return helperlistDetails;
          }
        } else {
          return (
            <tr>
              <td colspan="11" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      }else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }

   componentDidMount() {
     document.title = PageTitle('Subscribers View');
   }

  render() {
  if(this.state.subscribersingledata!==undefined){
    var subscriberslistdata = this.state.subscribersingledata;
    
  }
 
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Subscribers" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
				<a className="cursor-pointer btn-arrow" href="/Subscribers">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
                <div className="title">
                  <h3>Subscriber Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(subscriberslistdata.subscribername ? subscriberslistdata.subscribername : subscriberslistdata.username)}</p>
                        </div>
                      </div>

                     
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.mobileno}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.email}</p>
                        </div>
                      </div>
                     
                    

                      </div>
                      </div>
                      <div className="title">
                      <h3>Subscription History</h3>
                    </div>
                    <div className="overflow">
                      <Table striped bordered hover>
                        <thead>
                        <tr>
                        <th>S.No</th>
                        <th>Subscription Category</th>
                        <th>Subscription Name</th>
                        <th>Amount</th>
                        <th>Subscribed On</th>
                        </tr>
                        </thead>
                        <tbody>{this.subscriberData()}</tbody>
                      </Table>
                    </div>
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    subscriberslistview: state.subscriberslistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCategoryList: (subscriberId) =>{
      //  dispatch({ type: GET_VIEWCATEGORYLIST,subscriberId });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));