import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  }  from 'react-places-autocomplete';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";	
	
const initialState = {
		
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			location:''
		};
		
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	   	this.handleChange = this.handleChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);


    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

	

	componentDidMount() {
		
		document.title = PageTitle('Add Location');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}

		

			
    }

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';

				
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}

				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}			
				
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
						location: formPayload.location,				
					status       :  status,
				};
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				axios.post(apiUrl+"location/add",formData,config).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						scrollToTop();
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/location'));
								}
								.bind(this),
								5000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							5000
							);
					}
					this.setState({Loading:false});
				});
			}
	}

	validateForm() {
		const {location} = this.state;
		let errors = 0;		
		if (!location) {
			errors++;
			$('.errorlocation').html('<span class="errorspan">Please fill the field</span>');
		}else if(location){
			$('.errorlocation').html('');
		}
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }


	
	  
	
	handleChangeaddress = location => {
		this.setState({ location });
	  };
	 
	  handleSelect = location => {

		geocodeByAddress(location)
		  .then(results => getLatLng(results[0]))
		  .then(latLng => console.log('Success', latLng))
		  .catch(error => console.error('Error', error));


		  this.setState({ location });
		//   console.log(results,'results[0]')
	  };
	  handleInputChange(event) {
		const {name, value} = event.target;    
		this.setState({	[name]: value });
	  }

  render() {
  	const {selectedOption,domainlist, specialist} = this.state;
	  var current = this;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="location" />  

	
	<div className="content">	
		<div className="content-wrapper">
		        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a className="cursor-pointer btn-arrow" href="/location">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
			<div className="title">
				<h4>Add Location</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
		<div className="form-row">
				<div className="form-left">
					<div className="form-group">
							<label>Location:</label>
							<input type="text" name="location" onChange={this.handleInputChange} className="form-control" value={this.state.location} autoComplete="off" placeholder='Location'/>
							<div className="errorlocation"></div>
														
							{/* <PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete> */}
					    </div>
				</div>
				<div className="form-right">
					  

						
						
						<div className="default-font">
							<div className="form-group">					
								<label>Status:</label>
									<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
										options={lang.common.status_option} 
										onChange={this.handleChange}
										/>
							</div>
						</div>
					</div>
				</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true ?
                                        <span>Loading</span>:<span>Submit</span> 
                                        }
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Add);