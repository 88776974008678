import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      productlist:'',
      domainlist:[],
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"product/outofstocklist",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  productlist: res.data.productlist, totalRecord: res.data.records_count}, function() {
            
              this.productlist();
            });
  
        }else{
          this.setState({  productlist: '', totalRecord: ''}, function() {
            
            this.productlist();
          });
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getproductlist(qs.stringify(postobject))
    axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  productlist: res.data.productlist, totalRecord: res.data.records_count}, function() {
            this.productlist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  // console.log(postObject);
  // this.props.getproductlist(qs.stringify(postObject));
  axios.post(apiUrl+"product/outofstocklist",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
        this.setState({  productlist: res.data.productlist, totalRecord: res.data.records_count}, function() {
          this.productlist();
        });
    }else{
      this.setState({  productlist:'', totalRecord: ''}, function() {
        this.productlist();
      });
    }
  }); 

} 



   componentWillReceiveProps(Props){

     if (Props.deleteproduct!== this.props.deleteproduct) {
      if (Props.deleteproduct[0]["status"] === "success") {
         
          $('.success_message').html('<div class="status_sucess"><h3>'+ Props.deleteproduct[0]["message"]+'</h3></div>');
           var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              // this.props.getproductlist(qs.stringify(postobject));
              axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
                if(res.data.status === 'success'){
           
                    this.setState({  productlist: res.data.productlist, totalRecord: res.data.records_count}, function() {
                      this.productlist();
                    });
          
                }
              }); 
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );


          this.setState({productlist: Props.deleteproduct[0]["productlist"]}, function() {
            this.productlist();
          });       
          }
          
      }

       if(Props.productactive !== this.props.productactive){
      if (Props.productactive[0]["status"] === "success") {

        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.productactive[0]["message"]+'</h3></div>');
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              // this.props.getproductlist(qs.stringify(postobject));
               axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  productlist: res.data.productlist, totalRecord: res.data.records_count}, function() {
          this.productlist();
        });

    }
  }); 


           
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );

      }
    }
     
   }

   deleteConfirm=(confirmstatus,id)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action-delete').removeClass('show');
      
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };

  axios.post(apiUrl+"product/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){  
      $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

      setTimeout(
      function() {
        $('.success_message').html('');
        window.location.reload(); 
      }
      .bind(this),
      3000
      );

          
    }
  }); 
}else{
  $('.confirm-action-delete').removeClass('show');	
}
}

activateproduct(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };

      axios.post(apiUrl+"product/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          window.location.reload(false);
        }
      }); 
}
copyproduct(id){
  this.setState({productid : id})
  $('.confirm-action').addClass('show');	
  }

productlist() {
    var productlist = this.state.productlist;
    if (productlist != "undefined" && productlist != null) {
    

      if (productlist.length > 0) {

        if (Object.keys(productlist).length > 0) {
          const helperlistDetails = productlist.map(
            (productlist, Index) => {
              let sno = Index+1;
              

               if(productlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
            
              return (
                <tr key={productlist.id}>
      					<td>{sno}</td>
      					<td>{productlist.name}</td>
      					<td>{productlist.catname}</td>
      					<td>${productlist.price}</td>
      					{/* <td>{productlist.quantity}</td> */}
      					{/* <td>{productlist.description}</td> */}
                <td>{status}</td>
                <td className="actiongroup" >
                     {/* <Link to={{ pathname: '/product-view/'+productlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link> */}
                    <Link to={{ pathname: '/product-edit/'+productlist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {/* {(status == 'Active'?<Link onClick={this.activateproduct.bind(this, productlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateproduct.bind(this, productlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      
                     
                    <Link onClick={this.copyproduct.bind(this,productlist.id)}  className="" title="Copy Product">  <i className="fa fa-copy" aria-hidden="true"></i></Link>

                     <Link onClick={this.deleteproduct.bind(this,productlist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="7" className="v-align-nr" style={{textAlign:"center"}}>
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="7" className="v-align-nr" style={{textAlign:"center"}}>
            No Result
          </td>
        </tr>
      );
    }
  }
  
  cloneConfirm =(confirmstatus)=>{
    if(confirmstatus == 'yes'){
			this.cloneproduct(this.state.productid);
			$('.confirm-action').removeClass('show');
		}else{
			$('.confirm-action').removeClass('show');	
		}
  }

  cloneproduct(id){
    var admin_id = localStorage.getItem('admin_id');
    axios.get(apiUrl+'product/setproductcopy?admin_id='+admin_id+'&id='+id)
    .then(res => {
    if(res.data.status === 'success'){
      // window.location.reload(); 
    }
    });
  }

  deleteproduct(id){
    this.setState({rowid : id})
    $('.confirm-action-delete-message').html('Are you sure delete the product?');	
    $('.confirm-action-delete').addClass('show');	
   }

  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Outofstock" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Out of Stocks</h3>
                </div>
               
          
                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                {/*  <div className="add_button">  
                    <a className="" href='/product-add'>Add</a>
                </div> */}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>product Name</th>
                  
			            <th>Category</th>
			            <th>Price </th>
			            {/* <th>Description</th> */}
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.productlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
              <div className="confirm-action">
								<p>Are you sure you want to clone ?</p>
								<a href="javascript://" onClick={this.cloneConfirm.bind(this, "yes")} className="btn">Yes</a>
								<a href="javascript://" onClick={this.cloneConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support">X</span>
						 </div>
            </div> 
        </div>  
      </div>
      <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.rowid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						  </div>
    </div>
    );
  }
}
export default (withRouter(List));