import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,appointmentURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const appointmentid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      appointmentid: appointmentid,
      appointmentlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :appointmentid
    };

    axios.post(apiUrl+"AppointmentHistory/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ appointmentlistdata: res.data.datalistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('appointment View');
   }
  render() {
  if(this.state.appointmentlistdata!==undefined){
    var appointmentlistdata = this.state.appointmentlistdata;
  }


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="AppointmentHistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
				<a className="cursor-pointer btn-arrow" href="/appointment-history">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
                <div className="title">
                  <h3>Appointment History</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Appointment ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.appointment_id}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Doctor<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.doctor_name}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Book For<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.book_for}</p>
                        </div>
                      </div>
                      

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.name}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Phone Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.phone}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.email}</p>
                        </div>
                      </div>

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>medical_certificate<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.name}</p>
                        </div>
                      </div> */}

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Date<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.date} {appointmentlistdata.hours}:{appointmentlistdata.minutes} {appointmentlistdata.meridiam}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Doctor Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.paid_status}</p>
                        </div>
                      </div>

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.transaction_id}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Charge ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.charge_id}</p>
                        </div>
                      </div> */}
                      { (appointmentlistdata.original_amount !='' && appointmentlistdata.original_amount !=null  )?
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Original Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${appointmentlistdata.original_amount}</p>
                        </div>
                      </div> : <></>}


                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    appointmentlistview: state.appointmentlistview
  }
}

export default (withRouter(View));