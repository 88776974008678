import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import { apiUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
        list:'',
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var loggedas = localStorage.getItem("loggedas");
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
      };  

      axios.post(apiUrl+"Revenue/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  list: res.data.list, totalRecord: res.data.records_count}, function() {
            
              this.list();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getproductlist(qs.stringify(postobject))
    axios.post(apiUrl+"Revenue/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  list: res.data.list, totalRecord: res.data.records_count}, function() {
            this.list();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"Revenue/list",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
        this.setState({  list: res.data.list, totalRecord: res.data.records_count}, function() {
          this.list();
        });
    }else{    
        this.setState({  list: '', totalRecord: ''}, function() {
          this.list();
        });
    }
  }); 

} 



   


list() {
    var list = this.state.list;
    if (list != "undefined" && list != null) {
      if (list.length > 0) {

        if (Object.keys(list).length > 0) {
          const helperlistDetails = list.map(
            (list, Index) => {
              let sno = Index+1;
              var revenu=0;
              if(list.revenue!=null)
              {
                revenu +=parseInt(list.revenue);
              }
              if(list.appt_revenue!=null)
              {
                revenu +=parseInt(list.appt_revenue);
              }

              return (
                <tr key={list.id}>
      					<td >{sno}</td>
                {list.order_id !== null && <td style={{textAlign: 'left'}}> {list.order_id}</td>}
                {list.appt !== null && <td style={{textAlign: 'left'}}> {list.appt}</td>}
                {list.provider_name !== null && <td style={{textAlign: 'left'}}> {list.provider_name}</td>}
                <td style={{textAlign: 'left'}}> ${revenu}</td>
                {/* {list.settlement == 'No'?<td> 
                    <input
                      type="checkbox"
                      onChange={() => this.confirmdeliverst(list.id)}
                      key={list.id} 
                    />Mark as settle </td>:<td>Settled</td>} */}
                   {list.created_on !== null && <td style={{textAlign: 'left'}}> {moment(list.created_on).format('MMM DD Y hh:mm A')}</td>}
      			    <td className="actiongroup" >
                     <Link  to={{ pathname: '/providerrevenue-view/'+list.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="8" className="v-align-nr" style={{textAlign:"center"}}>
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="8" className="v-align-nr" style={{textAlign:"center"}}>
            No Result
          </td>
        </tr>
      );
    }
  }

  confirmdeliverst = (i) => {
		this.setState({listid: i})	
		$('.confirm-action').addClass('show');		
	}

  changestatus = (confirmstatus) => {
    if(confirmstatus == 'yes'){
      this.Updatedeliverst(this.state.listid);
      $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
      
  }

  Updatedeliverst = (id) =>{

    var qs = require('qs');
    var postObject = {
    admin_id : localStorage.getItem("admin_id"),
    id  :id
    };
    axios.post(apiUrl+"Revenue/changestatus",qs.stringify(postObject)).then(res1 => {      
    if(res1.data.status === 'success'){
        $('.success_message').html('<div class="status_sucess"><h3> Settlement Status changed</h3></div>');
        setTimeout(
          function() {
          
            $('.success_message').html('');
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var loggedas = localStorage.getItem("loggedas");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id,
              loggedas: loggedas
            };  

            axios.post(apiUrl+"Revenue/list",qs.stringify(postobject)).then(res => {
              if(res.data.status === 'success'){
                
                  this.setState({  list: res.data.list, totalRecord: res.data.records_count}, function() {
                  
                    this.list();
                  });

              }
            }); 
              
          }
          .bind(this),
          3000
          );
          
    }
    }); 
} 

  render() {
    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
    let catImg = '';
    let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ProviderRevenue" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Provider Revenues</h3>:<h3>{this.state.totalRecord} Provider Revenue</h3>}
                </div>
               

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Appointment ID</th>
                    <th>Provider</th>
                    {/* <th>Revenue</th> */}
                    <th>Amount</th>
                    <th>Created On</th>
                    
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.list()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
        <div className="confirm-action">
        <p>Before confirming yes, could you please confirm if the revenue has been settled?</p>
        <a href="javascript://" onClick={this.changestatus.bind(this, "yes")} className="btn">Yes</a>
        <a href="javascript://" onClick={this.changestatus.bind(this, "no")} className="btn">No</a>
        </div>
    </div>
    );
  }
}
export default (withRouter(List));