import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,specialistURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';
import parser from 'html-react-parser';


class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const categoryid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      categoryid: categoryid,
      specialistdata:'',
      productCategory:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :categoryid
    };

    axios.post(apiUrl+"specialist/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
        console.log( res.data.product_category)
          this.setState({ specialistdata: res.data.specialistdata[0]});
          this.setState({ productCategory: res.data.product_category[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Category View');
   }
  render() {
  if(this.state.specialistdata!==undefined){
    var specialistdata = this.state.specialistdata;
  }
  let loaderimg = '';
  let preview = '';

  const imagepre = specialistURL+'/'+specialistdata.image;
		
  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
    loaderimg = specialistURL + '/'+specialistdata.image;
    preview = <img className="img_class" src={loaderimg} alt="" />;
  // alert('hh');
  }else{
    preview = <img className="img_class" src={imagepre} alt="" />;
  }
  


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Specialist" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
                  <a className="cursor-pointer btn-arrow" href="/specialist">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
                  </a>
                  </div>
                <div className="title">
                  <h3>Specialist</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Specialist Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{specialistdata.cat_name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Module<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{specialistdata.module}</p>
                        </div>
                      </div>
                     
                      {specialistdata.module=='module2'?   
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Category Name <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productCategory.product_category}</p>
                        </div>
                      </div> :''}
                      {specialistdata.description!=''&&specialistdata.description!=undefined&&
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{parser(specialistdata.description)}</p>
                        </div>
                      </div>}
                      {specialistdata.short_description!=''&&specialistdata.short_description!=undefined&&
                       <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{parser(specialistdata.short_description)}</p>
                        </div>
                      </div>}
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Button Text<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{specialistdata.button_text}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(specialistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Specialist Image<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          {preview}
                        </div>
                      </div>
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylistview: state.categorylistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCategoryList: (categoryid) =>{
       dispatch({ type: GET_VIEWCATEGORYLIST,categoryid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));