import { SET_VIEWDOCTORLIST } from '../actions';

const doctorlistview = (state = [], action) => {
  switch (action.type) {
    case SET_VIEWDOCTORLIST:
      return [...action.value];  
    default: return state;
  }
}

export default doctorlistview;
