import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,faqURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const faq_id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      faq_id: faq_id,
      faqlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :faq_id
    };

    axios.post(apiUrl+"FaqCategory/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ faqlistview: res.data.categorylistview});
      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Faq View');
   }
  render() {
  if(this.state.faqlistview!==undefined){
    var faqlistview = this.state.faqlistview;
  }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Faq" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
                <a className="cursor-pointer btn-arrow" href="/Faqcategory">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
                </a>
              </div>
                <div className="title">
                  <h3>FaqCategory Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{faqlistview.cate_name}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(faqlistview.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                     
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));