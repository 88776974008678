import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import CKEditor from 'ckeditor4-react';
// import MyEditor from './MyEditor'
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			offer_title:'',
			title:'',
			description:'',
			content:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			selectedtype : '',
			selectedtypeValue : '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			categorynamelist: [],
			selectedTypeValue: '',
			showCategoryDropdown: false,
			selectedCategoryValue:'',
			button_name:'',
			tab_image:'',
			galleryimg: 'No',
			short_description: '',
			agreedescription:'',
			abdescriptions_name:'',
			ad_link:'',
			button_text:'Book',
			module_type:'',
			module_type_value:'',	
			selectedproductvalue:'',
			selectedOptiondata:'',
			selectedLinkOption:'',
			productnamelist:'',		
			selectedproductOption:'',
		};

		this.handleInputChange = this.handleInputChange.bind(this);		
		this.handleChange = this.handleChange.bind(this);
		this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
		this.removeImagebyindex = this.removeImagebyindex.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
		this.onEditorChangedrescription = this.onEditorChangedrescription.bind(this);
		this.onEditorChangeshort_description = this.onEditorChangeshort_description.bind(this);
		
    }
	
	componentDidMount() {
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'cmsmanagement/productlist?admin_id='+admin_id)	
		.then(res => {
			if(res.data.status == 'success'){	
				this.setState({		
					productnamelist: [{value: '', label: 'Select Product'}].concat(res.data.productnamelist)
				});
			}
		});
    }
	handleTypeChange = selectedOption => {
		const selectedTypeValue = selectedOption.value;
		this.setState({ 
			selectedtype: selectedOption,
			selectedtypeValue: selectedOption.value,
			showCategoryDropdown: false,
			categorynamelist: [] 
		  });
		
    };  
	handleChange = (r,selOption) => {

        if(r == 'status'){

			this.setState({selectedOption:selOption})

			this.setState({  selectedvalue : selOption.value});

		}else if(r == 'product'){			

			this.setState({selectedproductOption:selOption})

			this.setState({  selectedproductvalue : selOption.value});

		}else if(r == 'inputtype'){			

			this.setState({selectedLinkOption:selOption})

			this.setState({  selectedLinkvalue : selOption.value});

			$('.errorlinktype').html('');

		}		

   	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }

  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,
		
	  });
	};
	reader.readAsDataURL(file);

	
  }
  uploadMultipleFiles = (e) =>{
	this.fileObj = [];

		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			//this.fileArray.push(this.fileObj[0][i]
			this.imageArray.push(this.fileObj[0][i])
		}
		
		this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	}
	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
	removeImagebyindex = (indexvalue) => {
		   
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	   
		for (let i = 0; i < list.length; i++) {
		  this.imageArray.push(list[i]);
		  this.fileArray.push(URL.createObjectURL(list[i]));
		}
	  
		  this.setState({ tab_image: this.imageArray })	
  }
	handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			
			const formPayload = this.state;
			var qs = require('qs');
			var status,modue_typeIs,product_id= '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			if(formPayload.module_type_value === '' && formPayload.module_type_value!== 0){
				modue_typeIs =formPayload.module_type.value;	
			}else{
				modue_typeIs = formPayload.module_type_value;
			}	
			if(formPayload.selectedLinkOption.value == 'product'){		
				if(formPayload.selectedproductvalue != '' && formPayload.selectedproductvalue!== 0){
					product_id =formPayload.selectedproductvalue;
				}
			}
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				offer_title: formPayload.offer_title,
				title : formPayload.title,
				description: formPayload.description,
				content_type: formPayload.selectedtypeValue,
				category:formPayload.selectedCategoryValue,
				status:  status,
				short_description: formPayload.short_description,				
				button_text:formPayload.button_text,
				link_type:formPayload.selectedLinkOption.value,		
				link:formPayload.ad_link,		
				modue_typeIs      :modue_typeIs,	
				product_id      :product_id				
			};
			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}		
			
				
					var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}       
			axios.post(apiUrl+"cmsmanagement/add",formData ).then(res => {
				if(res.data.status === "success"){	
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								
								$('.success_message').html('');
								this.props.history.push('offers');
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
}

validateForm() {
	//const {admin_company_name,admin_fname} = this.state;
	const {offer_title,short_description,description,selectedtypeValue,image ,tabdescriptions_name,selectedtype,button_text,selectedLinkOption,ad_link,selectedproductvalue,module_type_value} = this.state;
	let errors = {};
	  let formIsValid = true;

	  if(selectedLinkOption.value == 'internal'){
		if (!module_type_value) {
			errors++;
			$('.errormodule').html('<span class="errorspan">Please select Module</span>');
		}else if(module_type_value){
			$('.errormodule').html('');
		}
	}
	if(selectedLinkOption.value == 'external'){
		if (!ad_link) {
			errors++;
			$('.errorad_link').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_link){
			$('.errorad_link').html('');
		}
	}
	if(selectedLinkOption.value == 'product'){
		if (!selectedproductvalue) {
			errors++;
			$('.erroroffers').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedproductvalue){	
			$('.erroroffers').html('');
		}
	}
	if (!button_text) {
		formIsValid = false;
		$('.errorbutton_text').html('<span class="errorspan">Please fill the field</span>');
	}else if(button_text){		
		$('.errorbutton_text').html('');
	}
	  
	if (!offer_title) {
		formIsValid = false;
		$('.erroroffer_title').html('<span class="errorspan">Please fill the  page title</span>');
	}else if(offer_title){
		if (/^[a-zA-Z\s]*$/.test(offer_title)) {
		$('.erroroffer_title').html('');
		}else{
			formIsValid = false;
			$('.erroroffer_title').html('Please enter valid  page title');
		}
	}
	
	if (!short_description) {
		formIsValid = false;
		$('.errorshort_description').html('<span class="errorspan">Please fill the short description</span>');
	}else if(short_description){
		$('.errorshort_description').html('');
	}

	
	if (!description) {
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the description</span>');
	}else if(description){
		$('.errordescription').html('');
	}
	
	
	
	
	

	this.setState({
		errors: errors
	});
	return formIsValid;
}

handleClickSelecetCategory = (event) => {
    const selectedValue = event.target.value; // Get the selected value from the event
    this.setState({ selectedCategoryValue: selectedValue }); // Set the selected value to the component's state
  }
  onEditorChangedrescription( evt ) {
	var callfunc = this;
	setTimeout(function () {
		callfunc.setState( {
			description: evt.editor.getData()
		});
	},1000);     
}
onEditorChangeshort_description( evt ) {
	var callfunc = this;
	setTimeout(function () {
		callfunc.setState( {
			short_description: evt.editor.getData()
		});
	},1000);     
}

handlefieldTypeChange(event) {   
	this.setState({module_type:[event],module_type_value:event.value})
}
  render() {

	const {selectedOption,selectedtype ,showCategoryDropdown,selectedLinkOption,selectedproductOption,module_type} = this.state;
	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	
	let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))
	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="offers" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="cursor-pointer btn-arrow" href="/offers">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
			<div className="title">
				<h4>Add Offer</h4>				
			</div>
			<form className="edit"  onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Page Title<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="offer_title" onChange={this.handleInputChange} className="form-control" value={this.state.offer_title}/>
					<div className="erroroffer_title"></div>
				</div>
				<div className="form-group">
					<label>Button text<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorbutton_text"></div>
				</div>
				<div className="form-group">
						<label>Short Description<span class="required" style={{ color: "red" }} >* </span> :</label>
						<CKEditor
								data={this.state.short_description}
								//data={templatecontent}
								onChange={this.onEditorChangeshort_description}
								type="classic"
							/> 
						{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
						<div className="errorshort_description"></div>	
				</div>
				
				
			   <div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">			
							{gallerylocal}
					</ul>
				</div> 
			</div>
                  
			<div className="form-right">
			<div className="form-group">
				<label>Link Type<span class="required" style={{ color: "red" }} >*</span></label>
				<Select
					value={selectedLinkOption?selectedLinkOption:""}
						options={lang.common.offer_linktype} 
						onChange={this.handleChange.bind(this, 'inputtype')}
						/>
				<div className="errorlinktype"></div>
				</div>
					{selectedLinkOption!=undefined&&<>{selectedLinkOption.value=='internal'&&
				<div className="form-group">
					<label>Module<span class="required" style={{ color: "red" }} >* </span> : </label>
					<Select 
						//value={this.state.module_type!=''?this.state.module_type:'Choose'}
						value={module_type!=''?module_type:'Choose module'}
						options={lang.common.ads_field_options} 
						onChange={this.handlefieldTypeChange.bind(this)}
						placeholder= 'Module'
						isSearchable={false}
					/>
					<div className="errormodule"></div>
				</div>}
				{selectedLinkOption.value=='external'&&
				<div className="form-group">
					<label>Link Url<span class="required" style={{ color: "red" }} >* </span> : </label>
					<input type="text" name="ad_link" onChange={this.handleInputChange} className="form-group" value={this.state.ad_link} />
					<div className="errorad_link"></div>
				</div>}
				{selectedLinkOption.value=='product'?
				<div className="form-group">
				<label>Products<span class="required" style={{ color: "red" }} > * </span> :</label>
				<Select 
					value={selectedproductOption?selectedproductOption:""}
					options={this.state.productnamelist} 
					onChange={this.handleChange.bind(this, 'product')}
				/>
				<div className="erroroffers"></div>
				</div>:''}</>}
					<div className="form-group">
						<label>Description<span class="required" style={{ color: "red" }} >*</span>:</label>
						
							{/* <CKEditor
								editor={ ClassicEditor }
								data=""
								onReady={ editor => {
									
								} }
								onChange={ ( event, editor ) => {
									const data = editor.getData();
									this.setState({description: data});
									
								} }
							/> */}
							<CKEditor
								data={this.state.description}
								//data={templatecontent}
								onChange={this.onEditorChangedrescription}
								type="classic"
							/> 

						<div className="errordescription"></div>  
						</div>
					<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange.bind(this,'status')}
                            />
	           		</div> 
				

				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);