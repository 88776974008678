import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,prescriptionURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const prescriptionid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      prescriptionid: prescriptionid,
      prescriptionlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :prescriptionid
    };

    axios.post(apiUrl+"prescription/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
          this.setState({ prescriptionlistdata: res.data.prescriptionlistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('prescription View');
   }
  render() {
  if(this.state.prescriptionlistdata!==undefined){
    var prescriptionlistdata = this.state.prescriptionlistdata;
  }
  if((prescriptionlistdata.prescription_detail!='')&&(prescriptionlistdata.prescription_detail!=null)){
    var prescription_detail = prescriptionlistdata.prescription_detail;
  }else{
   var prescription_detail = "N/A";
  }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Prescription" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
                <a className="cursor-pointer btn-arrow" href="/prescription">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
                </a>
              </div>
                <div className="title">
                  <h3>Prescription View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Medicine Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{prescriptionlistdata.medicine_name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Price<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${prescriptionlistdata.price}</p>
                        </div>
                      </div>
                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Quantity<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{prescriptionlistdata.quantity}</p>
                        </div>
                      </div> */}

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Prescription Detail<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{prescription_detail}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Prescription Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(prescriptionlistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>

                      </div>
                      </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    prescriptionlistview: state.prescriptionlistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewprescriptionList: (prescriptionid) =>{
       dispatch({ type: GET_VIEWCATEGORYLIST,prescriptionid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));