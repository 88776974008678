import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl,doctorURL} from'../Config/Config';
import axios from 'axios';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const doctorid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      doctorid: doctorid,
      doctorlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id  :doctorid
    };
    axios.post(apiUrl+"doctor/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
     
          this.setState({ 
            doctorlistview: res.data.doctorlist,
            specialistname: res.data.specialist.cat_name,
            domainname: res.data.domaindata.label,
          });

      }
    }); 
  }

   
   componentDidMount() {
     document.title = PageTitle('Doctor View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.doctorlistview!==this.state.doctorlistview){
        if(Props.doctorlistview[0].status == "success"){
          this.setState({doctorlistview: Props.doctorlistview[0]}) 
        }
      }
   }


  
  render() {



    var mobileno = ((this.state.doctorlistview.phone === null)||((this.state.doctorlistview.phone === ''))) ?  this.state.doctorlistview.phone = 'N/A' : this.state.doctorlistview.phone;

    var lastname = ((this.state.doctorlistview.lastname === null)||(this.state.doctorlistview.lastname === "")) ?  this.state.doctorlistview.lastname = 'N/A' : this.state.doctorlistview.lastname;

  // image view section

    let loaderimg = '';
    let preview = '';
  
    const imagepre = doctorURL+'/'+this.state.doctorlistview.image;
      
    if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
      loaderimg = doctorURL + '/'+this.state.doctorlistview.image;
      preview = <img className="img_class" src={loaderimg} alt="" />;
    // alert('hh');
    }else{
      preview = <img className="img_class" src={imagepre} alt="" />;
    }
  // ====================
    
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ongoingride" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
				<a className="cursor-pointer btn-arrow" href="/doctors">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
                <div className="title">
                  <h3>Provider Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.doctorlistview.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b> Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.doctorlistview.name}</p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.doctorlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Mobile number<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{mobileno}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Graduation<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.doctorlistview.graduation}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Specialist<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.specialistname}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Assign Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.domainname}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Years of experience<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.doctorlistview.experience_yr ? this.state.doctorlistview.experience_yr+ ' yrs':''}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Consultant Fee<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.doctorlistview.consultant_fee? '$'+this.state.doctorlistview.consultant_fee:''}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Location<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.doctorlistview.location}</p>
                          </div>                          
                        </div>
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>About Us<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.doctorlistview.description}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Don't distrub<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.doctorlistview.dont_distrub?'Yes':'No'}</p>
                          </div>                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{(this.state.doctorlistview.status=='A')?'Active':'Inactive'}</p>
                          </div>                          
                        </div>
                        {(this.state.doctorlistview.admin_type!== null && this.state.doctorlistview.adminname!== undefined) && <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status Changed by<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.doctorlistview.adminname+' ('+this.state.doctorlistview.admin_type+')'}</p>
                          </div>
                          </div>}


                    </div>
                  </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));