import React, { Component } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useState } from "react";
import { Button } from 'react-bootstrap';
import CKEditor from 'ckeditor4-react';
	
class Add extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			specialists_name:'',
			short_description:'',
			description:'',
			image:'',
			loader_image_preview:'',
			loader_image_name: '',
			loader_image: 'file',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedModule:"",
			selectedCategory:'',
			selectedvalue:'active',
			selectedmodulevalue:'module1',
			selectedcategoryvalue:'',
			productcategorynamelist:[],
			category_name:'',
			selectedcategoryid:'',
			title_description:'',
			image_preview:'',
			image_name:'',
			fileExtension: '',
			button_text:'',

		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleModuleChange = this.handleModuleChange.bind(this);
		this.onEditorChange1 = this.onEditorChange1.bind( this );
		this.onEditorChange = this.onEditorChange.bind( this );
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
		this.removeImagebyindex = this.removeImagebyindex.bind(this);
    }
	componentDidMount() {

		$('.category-section').hide();

  var admin_id = localStorage.getItem('admin_id');
	axios.get(apiUrl+'specialist/getproductcategorynamelist?admin_id='+admin_id)
		.then(res => {
			if(res.data.status == 'success'){			
				this.setState({
					productcategorynamelist:res.data.productcategorynamelist
				});
			
			}else{
			}
		});
		
	}


	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   	};
   	handleModuleChange = selectedModule =>{
    	this.setState({selectedModule})
        this.setState({  selectedmodulevalue : selectedModule.value});
		if(selectedModule.value == "module2")
		{
			$('.category-section').show();
		}else{
			this.setState({category_name: ''});
			$('.category-section').hide();
		}
   	}
   	handleCategoryChange = selectedCategory =>{
		this.setState({selectedCategory})
		this.setState({  selectedcategoryvalue : selectedCategory.value});
	}

   	handleInputChange(event) {
      	const {name, value} = event.target;      
      	this.setState({
          [name]: value
        });

  	}
  	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		};
		reader.readAsDataURL(file);
	}

  handleClickSelecetCategory = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');

	this.setState({
			category_name: keys,	
	});

}


  handleSubmit = () => {
	
	if(this.validateForm()){
	const formdata = this.state;
	var qs = require('qs');
	var status = '';
	var category_name ="";
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.selectedmodulevalue === ''){
		module =formdata.selectedmodulevalue.value;
		}else{
		module = formdata.selectedmodulevalue;
	}
	// if(formdata.category_name != ''){
	// 	category_name = formdata.category_name;
	// 	}else{
	// 	category_name = null;
	// }
	
	            const selectedcategoryids = formdata.selectedcategoryid;
				var elements=[];
				let catList  = ''
				if(selectedcategoryids!=='' && typeof selectedcategoryids!==undefined && selectedcategoryids!==null) {
					catList = selectedcategoryids.map((number) =>
					elements.push(number.value)
				);
				}
	          const selectedcategorys=elements.toString();

	var img_name = this.state.image;
	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		name : formdata.specialists_name,
		module : module,
		product_category_id:selectedcategorys,
		description: formdata.description,
		short_description: formdata.short_description,
		title_description: formdata.title_description,
		image : formdata.image,
		button_text: formdata.button_text,
		status: status
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}   
		axios.post(apiUrl+"specialist/add",formData,config).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html(this.props.history.push('/specialist'));
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		
	}
  }

  
	validateForm() {
		const {short_description,specialists_name,selectedmodulevalue,category_name,selectedcategoryid,button_text} = this.state;

		let errors = {};
      	let formIsValid = true;

		
      	if(!specialists_name){
        	formIsValid = false;
        	$('.errorspecialists_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(specialists_name){
			
			$('.errorspecialists_name').html('');
		}

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}

		if (!button_text) {
			formIsValid = false;
			$('.errorbutton_text').html('<span class="errorspan">Please fill the field</span>');
		}else if(button_text){
			$('.errorbutton_text').html('');
		}

		if(selectedmodulevalue == 'module2'){
			if(!selectedcategoryid){
			formIsValid = false;
			$('.errorproductcategory').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorproductcategory').html('');
			}
		}
     
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleProductCategoryChange = (event) => {
		console.log(event,'event')
		this.setState({selectedcategoryid : event}, function() {
		   const user_list = this.state.selectedcategoryid;
		 
		});
   }
   handleMenuClose = () => {
    const categoryToRender = this.state.productcategorynamelist.filter(member => member.value)
    const numRows = categoryToRender.length
    	const catList = [];
          this.state.productcategorynamelist.map(function(item){  
            catList.push({
                  value: item.value,
                  label: item.label
              });
          });
          this.setState({productcategorynamelist:catList});
    
    }

	onEditorChange1( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            description: evt.editor.getData()
        } );
		}, 1000);
     
    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            short_description: evt.editor.getData()
        } );
		}, 1000);
     
    }

	uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i]
				this.imageArray.push(this.fileObj[0][i])
	
			}
			
			this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
		}
		removeImagebyindex = (indexvalue) => {
		   
			var list = [...this.imageArray];
			this.imageArray = [];
			this.fileArray = [];
			list.splice(indexvalue, 1);
		   
			for (let i = 0; i < list.length; i++) {
			  this.imageArray.push(list[i]);
			  this.fileArray.push(URL.createObjectURL(list[i]));
			}
		  
			  this.setState({ tab_image: this.imageArray })	
	  }

  render() {

	let preview = '';
	const {selectedOption,selectedModule,productcategorynamelist	} = this.state;

	var current = this;	

	let imageslist = [];

	let indexlength =  imageslist.length;

  const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
	  <li className="thumb" key={indexlength+index}  >
		  <span><img src={url}  alt="..." /></span>
		  <a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
	  </li>
  ))
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Specialist" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="cursor-pointer btn-arrow" href="/specialist">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
			<div className="title">
				<h4>Add Specialists</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Specialists Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="specialists_name" onChange={this.handleInputChange} className="form-control" value={this.state.specialists_name}/>
					<div className="errorspecialists_name"></div>
				</div>
				<div className="form-group category-section">	
					<label>Product Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				   
					<Multiselect
					displayValue="label"
					onKeyPressFn={function noRefCheck(){}}
					onRemove={this.handleProductCategoryChange}
					onSearch={function noRefCheck(){}}
					onSelect={this.handleProductCategoryChange}
					value={this.state.selectedcategoryid}

					options={this.state.productcategorynamelist}
					/>
						
										<div className="errorproductcategory"></div>
				 </div>
				<div className="form-group">					
					<label>Short Description<span class="required" style={{ color: "red" }} > * </span> :</label>
					{/* 	<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  /> */}
						<CKEditor
					data={this.state.short_description}
					//data={templatecontent}
					name="short_description" 
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="errorshort_description"></div>
				</div>

				<div className="form-group">					
					<label>Title Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name="title_description" onChange={this.handleInputChange} className="form-control" value={this.state.title_description}  />
				</div>


				<div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
			</div> 
			<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />

	           </div> 

			</div>
		
			<div className="form-right">
				<div className="form-group">					
					<label>Module <span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedModule?selectedModule:{ value: 'module1', label: 'Module1 (Doctor Listing)'}}
                            options={lang.common.module_option} 
							isSearchable = {false}
                            onChange={this.handleModuleChange}
                            />
	           </div> 
			

				<div className="form-group">					
					<label>Description:</label>
					<CKEditor
					data={this.state.description}
					//data={templatecontent}
					name="description" 
					onChange={this.onEditorChange1}
					type="classic"
					/>
						
					<div className="errordescription"></div>
				</div>
				
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
				<div className="form-group">
					<label>Button Text</label>
				    <input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorbutton_text"></div>
				</div>
				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));