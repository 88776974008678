import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import moment from 'moment';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const referenceid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      referenceid: referenceid,
      referencelistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :referenceid
    };

    axios.post(apiUrl+"reference/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ referencelistdata: res.data.referencelistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Reference View');
   }
  render() {
  if(this.state.referencelistdata!==undefined){
    var referencelistdata = this.state.referencelistdata;
  }


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="referaluserlist" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
				<a className="cursor-pointer btn-arrow" href="/referaluserlist">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
                <div className="title">
                  <h3>Invited User Deatils</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Joined user<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{referencelistdata.joinuser}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Referred by<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{referencelistdata.refereruser}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Invited via<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{referencelistdata.refered_type}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Joining date<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{moment(referencelistdata.created_on).format("MMM-D-Y H:m A")}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Discount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{referencelistdata.discount}</p>
                        </div>
                      </div>
                      {referencelistdata.refererlocation&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>location<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          <p>{referencelistdata.refererlocation}</p>
                        </div>
                      </div>}
                      
                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}


export default (withRouter(View));