import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,prescriptionURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const prescriptionId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            prescriptionId: prescriptionId,
			medicine_name:'',
			price:'',
			// quantity:'',
			prescription_detail:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:''
		};
		var qs = require('qs');
		var pid = this.state.prescriptionId;

		axios.get(apiUrl+"prescription/listdetail?id="+pid).then(res => {
			this.setState({medicine_name:res.data.prescriptionlist.medicine_name,price:res.data.prescriptionlist.price,
				// quantity:res.data.prescriptionlist.quantity,
				prescription_detail:res.data.prescriptionlist.prescription_detail});
			if(res.data.prescriptionlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     	
    }


	componentDidMount() {
		document.title = PageTitle('prescription Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					medicine_name : formPayload.medicine_name,
					price: formPayload.price,
					// quantity: formPayload.quantity,
					prescription_detail: formPayload.prescription_detail,
					status:  status,
					id: formPayload.prescriptionId,
					image: formPayload.image
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"prescription/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/prescription'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		const {medicine_name,
			price,
			// quantity,
			prescription_detail} = this.state;
	
			let errors = {};
			  let formIsValid = true;
	
			if (!medicine_name) {
				formIsValid = false;
				$('.errormedicine_name').html('<span class="errorspan">Please fill the field</span>');
			}else if(medicine_name){
				$('.errormedicine_name').html('');
			}
			if (!price) {
				formIsValid = false;
				$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
			}else if(price){
				$('.errorprice').html('');
			}
			// if (!quantity) {
			// 	formIsValid = false;
			// 	$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
			// }else if(quantity){
			// 	$('.errorquantity').html('');
			// }

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){


   	if(Props.prescriptiondetails !== this.props.prescriptiondetails){
   		
    		if(Object.keys(Props.prescriptiondetails).length > 0){
				this.setState({Loading:false});

					if(Props.prescriptiondetails[0].status === "success"){

						const formdata = Props.prescriptiondetails[0].prescriptionlist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						this.setState({medicine_name:formdata.medicine_name,price:formdata.price,
							// quantity:formdata.quantity,
							prescription_detail:formdata.prescription_detail});
                        this.setState({prescriptionId: formdata.id});
					}
    		}
    	}

    	if(Props.prescriptionedit !== this.props.prescriptionedit){
    		if(Object.keys(Props.prescriptionedit).length > 0){
				if(Props.prescriptionedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.prescriptionedit[0].prescriptionlist;

				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});	
					}	
					this.setState({medicine_name:formpayload.medicine_name,price:formpayload.price,
						// quantity:formpayload.quantity,
						prescription_detail:formpayload.prescription_detail});
                        this.setState({prescriptionId: formpayload.id});
			
                    	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.prescriptionedit[0].message+'</h3></div>');
               			scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/prescription'));
						}
						.bind(this),
						3000
						);
						
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.prescriptionedit[0].message+'</h3></div>');
					scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }


  render() {


  	  const {selectedOption} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Prescription" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="cursor-pointer btn-arrow" href="/prescription">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
			<div className="title">
				<h4>prescription Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Medicine Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="medicine_name" onChange={this.handleInputChange} className="form-control" value={this.state.medicine_name}/>
					<div className="errormedicine_name"></div>
				</div>
				{/* <div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div> */}
				<div className="form-group">					
					<label>Prescription Detail:</label>
						<textarea type="text" name='prescription_detail' onChange={this.handleInputChange} className='form-control' value={this.state.prescription_detail} />
					<div className="errorprescription_detail"></div>
				</div>
			</div>
                  
			<div className="form-right">

			<div className="form-group">
					<label>Price($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>

				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
             

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}
export default (withRouter(Edit));