/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDUSER, SET_ADDUSER, GET_USERLIST,SET_USERLIST,GET_EDITUSER,SET_EDITUSER,GET_DELETEUSER, SET_DELETEUSER, GET_ACTIVATEUSER, SET_ACTIVATEUSER, GET_EXPORTUSER, SET_EXPORTUSER,GET_USERDETAILLIST, SET_USERDETAILLIST,GET_IMPORTUSER,SET_IMPORTUSER,GET_VIEWDOCTORLIST,SET_VIEWDOCTORLIST } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';



/*list of user*/
export const watchGetViewDoctorList = function* () {
  yield takeEvery(GET_VIEWDOCTORLIST, workerGetViewDoctorList);
}

function* workerGetViewDoctorList({ userid }) {
  try {
    const result = yield call(getViewDoctorList, userid);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VIEWDOCTORLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getViewDoctorList(userid){
   return Axios.post(apiUrl+'doctor/emrview', userid);
}