import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const productid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      productid: productid,
      productlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :productid
    };

    axios.post(apiUrl+"product/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
          this.setState({ productlistdata: res.data.productlistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('product View');
   }
  render() {
  if(this.state.productlistdata!==undefined){
    var productlistdata = this.state.productlistdata;
  }
  if((productlistdata.description!='')&&(productlistdata.description!=null)){
    var product_detail = productlistdata.description;
  }else{
   var product_detail = "N/A";
  }

  
  let loaderimg = '';
  let preview = '';

  const imagepre = productURL+'/'+productlistdata.image;
		
  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
    loaderimg = productURL + '/'+productlistdata.image;
    preview = <img className="img_class" src={loaderimg} alt="" />;
  }else{
    preview = <img className="img_class" src={imagepre} alt="" />;
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Product" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
				<a className="cursor-pointer btn-arrow" href="/product">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
                <div className="title">
                  <h3>Product View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.name}</p>
                        </div>
                      </div>
                   
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.catname}</p>
                        </div>
                      </div>
                      {productlistdata.product_type>0&&
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>
                              {productlistdata.product_type==1&&"Normal Product"}
                              {productlistdata.product_type==2&&"Shopping Product"}
                              {productlistdata.product_type==3&&"Regular Product"}
                            </p>
                        </div>
                      </div>}
                   
                      {productlistdata.product_type!=1&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Price<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${productlistdata.price}</p>
                        </div>
                      </div>}
                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Quantity<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productlistdata.quantity}</p>
                        </div>
                      </div> */}

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>product Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{product_detail}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>product Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(productlistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Image<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          {preview}
                        </div>
                      </div>
                    

                      </div>
                      </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    productlistview: state.productlistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewproductList: (productid) =>{
       dispatch({ type: GET_VIEWCATEGORYLIST,productid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));