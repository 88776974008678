import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            id: id,
			request_by:'',
			product_name:'',
			productid:'',
			provider_id:'',
			provider_qty:''
		};
		
		var qs = require('qs');
		var invid = this.state.id;

		axios.get(apiUrl+"requestinventory/listdetail?id="+invid).then(res => {
			this.setState({product_name:res.data.detail.requested_for,request_by:res.data.detail.request_by,productid:res.data.detail.product_id,provider_id:res.data.detail.provider_id});
			
		
		}); 

	
		this.handleInputChange = this.handleInputChange.bind(this);

    }

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
	
    }
	

	componentDidMount() {
		document.title = PageTitle('Requested Inventory');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
			
				const formdata = this.state;
				var qs = require('qs');
				var status = '';
		
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}
				var postObject ={
					admin_id: localStorage.getItem('admin_id'),
					id: formdata.id,
					provider_id: formdata.provider_id,
					product_id: formdata.productid,
					provider_qty:formdata.provider_qty,
				}
			       
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				axios.post(apiUrl+"requestinventory/edit",formData,config).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/requestinventory'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {provider_qty,selecteddoctor,product_name} = this.state;
		let errors = {};
      	let formIsValid = true;
		  if (!provider_qty) {
			formIsValid = false;
			$('.errorprovider_qty').html('<span class="errorspan">Please enter price</span>');
		}else if(provider_qty){
			$('.errorprovider_qty').html('');
		}

		this.setState({
			errors: errors
		});
		console.log(formIsValid,'errors')
		return formIsValid;
    }

  
  render() {

	
	const {productlist,doctorlist, productid} = this.state;

  	var current = this;
    return (
	
		<div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Providerinventory" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="cursor-pointer btn-arrow" href="/requestinventory">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
			<div className="title">
				<h4>Requested Inventory</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >
			<div className="form-row">
			<div className="form-left">
			     <div className="form-group">
					<label>Request Product :
					{this.state.product_name} </label>
				 </div>
				 <div className="form-group">
					<label>Request By :
					Dr.{this.state.request_by} </label>
				 </div>
				
			</div>
                  
			<div className="form-right">
			<div className="form-group">					
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> :</label>
						<input type="text" name="provider_qty" onChange={this.handleInputChange} className="form-control" value={this.state.provider_qty}  />
					<div className="errorprovider_qty"></div>
				</div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));