import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


import {GET_EDITCOMPANY,GET_COMPANYDETAILLIST }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const CompanyId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			admin_company_name:'',
			admin_fname:'',
			admin_username:'',
			admin_contact:'',
			admin_email_address:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			user_password:'',
			user_confirm_password:'',
			c_company_id: CompanyId,
			Loading:false,
			count_domain:'',
		};
		this.props.getCompanyDetailList(CompanyId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
        this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }
	handleChangeTxt = (item, event) => {  
        this.setState({[item]:event.target.value }); 
    }
	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
	}
		
	confim_pass_show_hide = () => {
		this.setState({
			 confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			 confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
	}   

	componentDidMount() {
		document.title = PageTitle('Company Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_company_id : formPayload.c_company_id,
					admin_company_name: formPayload.admin_company_name,					
					admin_username:formPayload.admin_username,
					admin_contact:formPayload.admin_contact,
					admin_email_address:formPayload.admin_email_address,	
					password:formPayload.user_password,
					domain_count:formPayload.count_domain,	
					admin_status:status,
				};
				//console.log(postObject);
				this.props.getEditCompany(qs.stringify(postObject));
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {count_domain,admin_company_name,admin_fname,admin_username,admin_email_address,admin_contact,user_password,user_confirm_password} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!admin_company_name) {
			formIsValid = false;
			$('.erroradmin_company_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_company_name){
			$('.erroradmin_company_name').html('');
		}	
		if (!count_domain) {
			formIsValid = false;
			$('.errorcount_domain').html('<span class="errorspan">Please fill the field</span>');
		}else if(count_domain){
			$('.errorcount_domain').html('');
		}
		if (!admin_username) {
			formIsValid = false;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_username){
			$('.erroradmin_username').html('');
		}				

		
		if (!admin_contact) {
			formIsValid = false;
			$('.erroradmin_contact').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_contact){
			$('.erroradmin_contact').html('');
		}

		if (!admin_email_address) {
			formIsValid = false;
			$('.erroradmin_email_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_email_address){
			$('.erroradmin_email_address').html('');
		}
		// if (!user_password) {
        //     formIsValid = false;
        //      $('.userpswd').html('<span class="errorspan">Please enter new password</span>');
        // }else if(user_password){
        //     $('.userpswd').html('');
        // }
        
        // if (!user_confirm_password) {
        //     formIsValid = false;
        //     $('.usercpswd').html('<span class="errorspan">Please enter confirm password</span>');
        // }else if(user_confirm_password){
        //     $('.usercpswd').html('');
        // }

        if(user_password !== user_confirm_password){
            formIsValid = false;
             $('.usercpswd').html('<span class="errorspan">Password does not match</span>');
        }

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.companydetails !== this.props.companydetails){
    		if(Object.keys(Props.companydetails).length > 0){
				this.setState({Loading:false});

					if(Props.companydetails[0].status === "success"){

						const formdata = Props.companydetails[0].companylist;
						if(formdata.admin_status === 'Yes'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({admin_company_name:formdata.admin_company_name});						
						//this.setState({c_company_id: formdata.c_company_id});		
						this.setState({count_domain: formdata.domain_count});				
						this.setState({admin_contact: formdata.admin_contact});
						this.setState({admin_email_address: formdata.admin_email_address});
						this.setState({admin_username: formdata.admin_username});
					}
    		}
    	}
    	if(Props.companyedit !== this.props.companyedit){
    		if(Object.keys(Props.companyedit).length > 0){
				if(Props.companyedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.companyedit[0].companylist;
				if(formpayload.admin_status === 'Yes'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({admin_company_name:formpayload.admin_company_name});
				
				//this.setState({c_company_id: formpayload.c_company_id});
				this.setState({admin_contact: formpayload.admin_contact});
				this.setState({admin_email_address: formpayload.admin_email_address});
				this.setState({admin_username: formpayload.admin_username});
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/company'));
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="companylist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="">
				
			<div class="content-wrapper-before"></div>
			<div className="car-listing-wrap leaa-admin-head card">
				<div className="success_message"></div>
				<div>
						<a className="cursor-pointer btn-arrow" href="/company">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
						</a>
					</div>
					<div className="title">
						<h4>Edit Details</h4>
					</div>
					<form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
					<div className="form-row">
					<div className="form-left">
						<div className="form-group">
							<label>Company Name<span class="required" style={{ color: "red" }} > * </span>:</label>
							<input type="text" name="admin_company_name" onChange={this.handleInputChange} className="form-control" value={this.state.admin_company_name} />
							<div className="erroradmin_company_name"></div>
						</div>
						
						
						<div className="form-group">					
							<label>Contact:</label>
								<input type="text" name="admin_contact" onChange={this.handleInputChange} className="form-control" value={this.state.admin_contact} />
							<div className="erroradmin_contact"></div>
						</div>
						<div className="form-group eye-pad">
                            <label>New Password</label>
                            <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleChangeTxt.bind(this, 'user_password')}/>
                            <span toggle="#password-field" className={'shown ' + this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                            <div className="userpswd"></div>
                        </div>
                        <div className="form-group eye-pad">
                            <label>Confirm Password</label>
                            <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleChangeTxt.bind(this, 'user_confirm_password')}/>
                            <span toggle="#password-field" className={'shown ' + this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                            <div className="usercpswd"></div>
                        </div>
					</div>	

					<div className="form-right">
						<div className="form-group">
							<label>User Name<span class="required" style={{ color: "red" }} > * </span>:</label>
							<input type="text" name="admin_username" onChange={this.handleInputChange} className="form-control" value={this.state.admin_username} />
							<div className="erroradmin_username"></div>
						</div>
						<div className="form-group">
							<label>Email<span class="required" style={{ color: "red" }} > * </span>:</label>
								<input type="text" name="admin_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.admin_email_address} />
							<div className="erroradmin_email_address"></div>
						</div>
						<div className="form-group">
							<label>Allowed count of domain<span class="required" style={{ color: "red" }} > * </span> :</label>
							<input type="number" className="form-control" name="count_domain"  onChange={this.handleInputChange} value={this.state.count_domain}/>
							<div className="errorcount_domain"></div>
						</div>
						<div className="form-group">					
							<label>Status:</label>
								<Select 
								value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									options={lang.common.status_option} 
									onChange={this.handleChange}
									/>
							<div className="erroradmin_email_address"></div>
						</div>
					</div>
					</div>		

					<div className="btn-group export">	
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
												<span className="load-data"></span> 
												}Submit
						</button>
					</div>
					</form>
				</div>	
			</div>			
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
   companydetails: state.companydetails,
   companyedit: state.companyedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetailList: (CompanyId) =>{
       dispatch({ type: GET_COMPANYDETAILLIST,CompanyId });
    },
     getEditCompany: (formPayload) =>{
       dispatch({ type: GET_EDITCOMPANY, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));