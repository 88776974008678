import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWDOCTORLIST } from '../../actions';
import { mediaUrl} from'../Config/Config';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import moment from 'moment';
class ViewEMR extends Component {

  constructor(props)
  {
    super(props); 
    const userid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
    this.state = {      
      userid: userid,
      doctorlistview:'',
      familtlistview:'',
      pastappointment:'',
      ongoingappts:'',
      futureappts:'',
      activetab: 'userdetail',  
      ShowFamilyDocuments:false,
      FamilyDocuments:'',
      medicalHistorylist:'',
      medicalCondition:'',
      ShowPrescriptionlist:'',
      Prescriptionlist:'',
      RaiseDisputelist:'',
      recommendedlist:'',
      ratinglist:'',
      ShowRecommendedProduct:false,
      recommendedProductlist:'',
      revenuelist:'',
      isActive:true,
    };

    var qs = require('qs');
    var postObject = {             
    id   :userid
    };
    this.props.getViewDoctorList(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('Provider View');
     if ($('.sidebar-menu').hasClass('menu-close')) {
               $('.sidebar-menu').removeClass('menu-close');
               $('.logo-box').removeClass('active');	
                 $('.header-cnt-left').removeClass('toggle-open');
     
           }else{
               $('.sidebar-menu').addClass('menu-close');
               $('.logo-box').addClass('active');
                 $('.header-cnt-left').addClass('toggle-open');
     
     
     
   }
  }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
    console.log(Props,'PropsPropsPropsPropsPropsProps')
      if(Props.doctorlistview!==this.state.doctorlistview){
        if(Props.doctorlistview[0].status == "success"){
          this.setState({doctorlistview: Props.doctorlistview[0].doctorlist});
       
          this.setState({pastappointment: Props.doctorlistview[0].pastappointment},function(){
            this.pastappointment();
          });
          this.setState({ongoingappts: Props.doctorlistview[0].ongoingappts},function(){
            this.ongoingappts();
          });
          this.setState({futureappts: Props.doctorlistview[0].futureappts},function(){
            this.futureappts();
          });
          this.setState({recommendedlist: Props.doctorlistview[0].recommendedlist},function(){
            this.recommendedlist();
          });

          this.setState({RaiseDisputelist:Props.doctorlistview[0].raise_dispute},function(){
            this.RaiseDisputelist();
          }) 
          this.setState({ratinglist:Props.doctorlistview[0].doctorrating},function(){
            this.Ratinglist();
          }) 
          this.setState({revenuelist:Props.doctorlistview[0].revenue_list},function(){
            this.Revenuelist();
          }) 

          
          

        //   this.setState({medicalHistorylist:Props.doctorlistview[0].usermedicalfiles},function(){
        // this.medicalHistory();
        //   }) 
        
        //   this.setState({medicalCondition:Props.doctorlistview[0].medicalcondition},function(){
        //     this.medicalCondition();
        //   })

        }
      }
   }
   

  pastappointment() {
    var appointments = this.state.pastappointment;
    if (appointments != "undefined" && appointments != null) {
      // if ( == "success"){
        if (Object.keys(appointments).length > 0) {
          const apptDlist = appointments.map(
            (appts, Index) => {
              let sno = Index+1;
              var apptstatus = 'Pending';
              if(appts.paid_status =='booked'){
                var apptstatus = 'Accepted'
              }else  if(appts.paid_status =='paid'){
                var apptstatus = 'Completed'
              }
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{appts.appointment_id}</td>
                 <td>{moment(appts.appointment_date).format('MMM DD Y hh:mm A')}</td>
                 <td>
                  {appts.medical_condition}
                  </td>
                  <td>
                  {appts.name}
                  </td>
                
                  <td>
                  {appts.gender}
                  </td>
                 
                  <td>
                  {appts.phone}
                  </td>
                  <td>
                    {apptstatus}
                  </td>

                  <td>
                  {appts.prescriptionlist.length>0?
                    <a class="" title="View" href="#" onClick={this.apptPrescriptionShow.bind(this,appts.prescriptionlist)}>  <i class="ft-eye" aria-hidden="true"></i></a>:'N/A'}
                 </td>
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }

  ongoingappts() {
    var appointments = this.state.ongoingappts;
    if (appointments != "undefined" && appointments != null) {
      // if ( == "success"){
        if (Object.keys(appointments).length > 0) {
          const apptDlist = appointments.map(
            (appts, Index) => {
              let sno = Index+1;
              var apptstatus = 'Pending';
              if(appts.paid_status =='booked'){
                var apptstatus = 'Accepted'
              }else  if(appts.paid_status =='paid'){
                var apptstatus = 'Completed'
              }
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{appts.appointment_id}</td>
                 <td>{moment(appts.appointment_date).format('MMM DD Y hh:mm A')}</td>
                 <td>
                  {appts.medical_condition}
                  </td>
                  <td>
                  {appts.name}
                  </td>
                
                  <td>
                  {appts.gender}
                  </td>
                  <td>
                  {appts.phone}
                  </td>
                  <td>
                    {apptstatus}
                  </td>
                  <td>
                    {appts.prescriptionlist.length>0?
                    <a class="" title="View" href="#" onClick={this.apptPrescriptionShow.bind(this,appts.prescriptionlist)}>  <i class="ft-eye" aria-hidden="true"></i></a>:'N/A'}

                    
                  </td>
                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }
  apptPrescriptionShow = (preslist)=>{
    this.setState({ShowPrescriptionlist:true,Prescriptionlist:preslist});
  }

  recommendedProductShow = (list)=>{
    this.setState({ShowRecommendedProduct:true,recommendedProductlist:list});
  }
  recommendedProductlist = ()=>{ 
    var preslist = this.state.recommendedProductlist;
    if (preslist != "undefined" && preslist != null) {
      // if ( == "success"){
        if (Object.keys(preslist).length > 0) {
          const apptDlist = preslist.map(
            (data, Index) => {
              let sno = Index+1;            
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{data.p_name}</td>
                 <td>{data.product_catname}</td>
                 <td>{(data.product_img!=''&&data.product_img!=null)&&<img src={data.product_img} alt={data.p_name} />}</td>                
                 <td>${data.single_price}</td>
                 <td>{data.quantity}</td>
                 <td>${data.total_price}</td>                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
    
  }
  apptPrescriptionlist = (preslist)=>{
 
    var preslist = this.state.Prescriptionlist;
    if (preslist != "undefined" && preslist != null) {
      // if ( == "success"){
        if (Object.keys(preslist).length > 0) {
          const apptDlist = preslist.map(
            (data, Index) => {
              let sno = Index+1;
            
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{data.prescription_name}</td>
                 <td>{data.quantity}</td>
                 <td>${data.price}</td>
                 {/* <td>{data.created_on}</td> */}
                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
    
  }
  futureappts() {
    var appointments = this.state.futureappts;
    if (appointments != "undefined" && appointments != null) {
      // if ( == "success"){
        if (Object.keys(appointments).length > 0) {
          const apptDlist = appointments.map(
            (appts, Index) => {
              let sno = Index+1;
              var apptstatus = 'Pending';
              if(appts.paid_status =='booked'){
                var apptstatus = 'Accepted'
              }else  if(appts.paid_status =='paid'){
                var apptstatus = 'Completed'
              }
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{appts.appointment_id}</td>
                 <td>{moment(appts.appointment_date).format('MMM DD Y hh:mm A')}</td>
                 <td>
                  {appts.medical_condition}
                  </td>
                  <td>
                  {appts.name}
                  </td>
                
                  <td>
                  {appts.gender}
                  </td>
                 
                  <td>
                  {appts.phone}
                  </td>
                  <td>
                    {apptstatus}
                  </td>
                  {/* <td> */}
                    {/* {appts.prescriptionlist.length>0?
                    <a class="" title="View" href="#" onClick={this.apptPrescriptionShow.bind(this,appts.prescriptionlist)}>  <i class="ft-eye" aria-hidden="true"></i></a>:''} */}

                    
                  {/* </td> */}
                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }
//   medicalCondition = ()=>{
//     var medicalCondition = this.state.medicalCondition;
//     if (medicalCondition != "undefined" && medicalCondition != null) {
//       // if ( == "success"){
//         if (Object.keys(medicalCondition).length > 0) {
//           const medlist = medicalCondition.map(
//             (data, Index) => {
//               let sno = Index+1;
              
//               return (
//                 <tr key={Index}>
// 				         <td>{sno}</td>
//                  <td> <p>  {data.medical_condition} </p></td>
//                  <td>{moment(data.created_on).format('MMM DD Y hh:mm A')}</td>
                 
                 
//                 </tr>
//               );
//             }
//           );
//           return medlist;
//         }
//       else {
//         return (
//           <tr>
//             <td colSpan="9" className="v-align-nr text-center">
//               No Result
//             </td>
//           </tr>
//         );
//       }
    
//     }
//   }
//   medicalHistory = ()=>{

//     var medicalHistorylist = this.state.medicalHistorylist;
//     if (medicalHistorylist != "undefined" && medicalHistorylist != null) {
//       // if ( == "success"){
//         if (Object.keys(medicalHistorylist).length > 0) {
//           const medlist = medicalHistorylist.map(
//             (medicalfile, Index) => {
//               let sno = Index+1;
              
//               return (
//                 <tr key={Index}>
// 				         <td>{sno}</td>
//                  <td> <p> <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="10.79 5 78.42 90" onClick={() => this.downloadImage(mediaUrl+"familymedical/"+medicalfile.image,medicalfile.image)} style={{width: '30px',height: '30px'}}
//  > <g data-name="pdf"><path d="M87,25.34,70.92,7.75A8.46,8.46,0,0,0,64.69,5H19.23a8.45,8.45,0,0,0-8.44,8.44V86.56A8.45,8.45,0,0,0,19.23,95H80.77a8.45,8.45,0,0,0,8.44-8.44V31A8.4,8.4,0,0,0,87,25.34Zm-5.33,2.51H71.31A2.82,2.82,0,0,1,68.5,25V13.44Zm-.9,61.53H19.23a2.82,2.82,0,0,1-2.82-2.82V13.44a2.82,2.82,0,0,1,2.82-2.82H62.87V25a8.45,8.45,0,0,0,8.44,8.44H83.59V86.56A2.82,2.82,0,0,1,80.77,89.38Z"/><path d="M67.22,28.89,66.5,9H14.67V91.33H85.5V29.12ZM40.4,53.48a6.21,6.21,0,0,1-1.79,2.22A7.6,7.6,0,0,1,36,57a11.54,11.54,0,0,1-3.16.42H31.17a1,1,0,0,0-.94.94v5.24a.94.94,0,0,1-.94.94H26.44a.94.94,0,0,1-.94-.94V44.46a.94.94,0,0,1,.94-.94h6.27a14.69,14.69,0,0,1,3.26.34A7.33,7.33,0,0,1,38.61,45a5.54,5.54,0,0,1,1.79,2.09,7.06,7.06,0,0,1,.66,3.21A7.24,7.24,0,0,1,40.4,53.48Zm18.73,5.15A8.69,8.69,0,0,1,57,61.93a8.58,8.58,0,0,1-3.24,2,12.9,12.9,0,0,1-4.2.65H44.35a.94.94,0,0,1-.94-.94V44.46a.94.94,0,0,1,.94-.94h5a14.65,14.65,0,0,1,4.35.61A8.78,8.78,0,0,1,57,46a8.28,8.28,0,0,1,2.12,3.24,13.07,13.07,0,0,1,.75,4.67A13.36,13.36,0,0,1,59.13,58.63ZM75.82,46.58a.94.94,0,0,1-.94.94H68.26a.94.94,0,0,0-.94.94v3a.94.94,0,0,0,.94.94h5.4a.94.94,0,0,1,.94.94v2.12a.94.94,0,0,1-.94.94h-5.4a.94.94,0,0,0-.94.94v6.27a.94.94,0,0,1-.94.94H63.52a.94.94,0,0,1-.94-.94V44.46a.94.94,0,0,1,.94-.94H74.88a.94.94,0,0,1,.94.94Z"/><path d="M53.37,48.76a4.33,4.33,0,0,0-1.87-1.08,8.5,8.5,0,0,0-2.45-.32h-.9V60.69h.9a7.84,7.84,0,0,0,2.45-.35,4.65,4.65,0,0,0,1.87-1.14,5.6,5.6,0,0,0,1.21-2.08A10.08,10.08,0,0,0,55,53.93a9.8,9.8,0,0,0-.43-3.14A5.23,5.23,0,0,0,53.37,48.76Z"/><path d="M35.39,48a5.42,5.42,0,0,0-3-.68H31.17a1,1,0,0,0-.94.94V52.7a1,1,0,0,0,.94.94h1.38q3.87,0,3.87-3.35A2.55,2.55,0,0,0,35.39,48Z"/></g></svg>  {medicalfile.image} </p></td>
//                  <td>{moment(medicalfile.created_on).format('MMM DD Y hh:mm A')}</td>
                 
                 
//                 </tr>
//               );
//             }
//           );
//           return medlist;
//         }
//       else {
//         return (
//           <tr>
//             <td colSpan="9" className="v-align-nr text-center">
//               No Result
//             </td>
//           </tr>
//         );
//       }
    
//     }

//   }

  RaiseDisputelist = ()=>{
    
    var RaiseDisputelist = this.state.RaiseDisputelist;
    if (RaiseDisputelist != "undefined" && RaiseDisputelist != null) {
      // if ( == "success"){
        if (Object.keys(RaiseDisputelist).length > 0) {
          const medlist = RaiseDisputelist.map(
            (data, Index) => {
              let sno = Index+1;
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td> <p>  {data.dispute} </p></td>
                 <td> <p>  {data.appointment_id} </p></td>
                 <td> <p>  {data.firstname} {(data.lastname!=''&&data.lastname!=null)&&data.lastname}</p></td>
                 <td> <p>  {data.dispute_message} </p></td>
                 {/* <td> <p>  {data.dispute_status=='A'?'Active':'Inactive'}</p></td> */}
                 
                 <td>{moment(data.created_on).format('MMM DD Y hh:mm A')}</td>
                                  
                </tr>
              );
            }
          );
          return medlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }    
    }
  }

  
  Ratinglist = ()=>{
    
    var ratinglist = this.state.ratinglist;
    if (ratinglist != "undefined" && ratinglist != null) {
      // if ( == "success"){
        if (Object.keys(ratinglist).length > 0) {
          const medlist = ratinglist.map(
            (data, Index) => {
              let sno = Index+1;
              return (
                <tr key={Index}>
				         <td>{sno}</td>                
                 <td> <p>  {data.appointment_id} </p></td>
                 <td> <p>  {data.firstname} </p></td>
                 <td> <p>  {data.rating}/5 </p></td>
                 <td> <p>  {data.rating_message} </p></td>                 
                 <td>{moment(data.created_on).format('MMM DD Y hh:mm A')}</td>                                  
                </tr>
              );
            }
          );
          return medlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }    
    }
  }

  recommendedlist = ()=>{
    var recommendedlist = this.state.recommendedlist;
    if (recommendedlist != "undefined" && recommendedlist != null) {
      // if ( == "success"){
        if (Object.keys(recommendedlist).length > 0) {
          const medlist = recommendedlist.map(
            (data, Index) => {
              let sno = Index+1;
              
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td> <p>  {data.firstname} </p></td>
                 <td> <p>  {data.product_count} </p></td>
                 <td> <p>  ${data.total_amt} </p></td>   
                 <td> <p>  {data.pay_status} </p></td>   
                 <td>{moment(data.created_on).format('MMM DD Y hh:mm A')}</td>
                 <td>
                    {data.productlist.length>0?
                    <a class="" title="View" href="#" onClick={this.recommendedProductShow.bind(this,data.productlist)}>  <i class="ft-eye" aria-hidden="true"></i></a>:''}

                 </td>
                                  
                </tr>
              );
            }
          );
          return medlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }    
    }
  }

  Revenuelist = ()=>{
    
    var revenuelist = this.state.revenuelist;
    if (revenuelist != "undefined" && revenuelist != null) {
      // if ( == "success"){
        if (Object.keys(revenuelist).length > 0) {
          const medlist = revenuelist.map(
            (data, Index) => {
              let sno = Index+1;              
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td> <p>  {data.appt!=null?'Appointments':'Orders'} </p></td>
                 <td> <p>  {data.appt!=null?data.appt:'#uxt00'+data.order_id} </p></td>
                 {/* <td> <p>  {data.user_fname!=null&&data.user_fname} </p></td>    */}
                 <td> <p>  ${(data.revenue&&data.appt_revenue)?parseInt(data.revenue)+parseInt(data.appt_revenue):data.appt_revenue!=null?data.appt_revenue:data.revenue} </p></td>   
                 {/* <td> <p>  {data.settlement} </p></td>    */}
                 <td>{moment(data.created_on).format('MMM DD Y hh:mm A')}</td>  
                </tr>
              );
            }
          );
          return medlist;
        }
      else {
        return (
          <tr>
            <td colSpan="6" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }    
    }
  }

  handleGoBack = () => {
    if(this.state.ShowPrescriptionlist){
      this.setState({ShowPrescriptionlist:false});
    }else if(this.state.ShowRecommendedProduct){
      this.setState({ShowRecommendedProduct:false});
    }else{
      this.props.history.goBack();
    }
    
  }
  handleClick = (index) => {
    this.setState({ activetab: index,ShowPrescriptionlist:false });
  };
  expandClick = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }
    ShowFamilyDocuments=(documents)=>{
      this.setState({ShowFamilyDocuments:true,FamilyDocuments:documents});
    }
    downloadImage(url, name) {
			fetch(url)
			.then(resp => resp.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = name;
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url);
			})
			.catch(() => alert('An error sorry'));
		}
  render() {
    const { activetab } = this.state;
     if((this.state.doctorlistview.subscribed_plan_name!='')&&(this.state.doctorlistview.subscribed_plan_name!=null)){
        if(this.state.doctorlistview.subscribed_plan_name == "Free"){

         var plan_paid_amount = "$"+0;
        }else{
           var plan_paid_amount = "$"+this.state.doctorlistview.plan_paid_amount;
        }
    }else{
     var plan_paid_amount = "N/A";
    }

    if((this.state.doctorlistview.subscribed_plan_name!='')&&(this.state.doctorlistview.subscribed_plan_name!=null)){
        var subscribed_plan_name = this.state.doctorlistview.subscribed_plan_name;
    }else{
     var subscribed_plan_name = "N/A";
    }


    if((this.state.doctorlistview.transactionId!='')&&(this.state.doctorlistview.transactionId!=null)){
        var transactionId = this.state.doctorlistview.transactionId;
    }else{
     var transactionId = "N/A";
    }

    var mobileno = ((this.state.doctorlistview.mobileno === null)||((this.state.doctorlistview.mobileno === ''))) ?  this.state.doctorlistview.mobileno = 'N/A' : this.state.doctorlistview.mobileno;

    var lastname = ((this.state.doctorlistview.lastname === null)||(this.state.doctorlistview.lastname === "")) ?  this.state.doctorlistview.lastname = 'N/A' : this.state.doctorlistview.lastname;


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="doctors" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                 <div>
                  <span className='cursor-pointer btn-arrow end' onClick={this.handleGoBack}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg></span> 
               </div>
               <div className={`${this.state.isActive ? "vertical-menu-bar active" : "vertical-menu-bar"}`} onClick={this.expandClick}>
               {/* <svg version="1.1" x="0px" y="0px" viewBox="8 18 84 64" onClick={this.expandClick}><g transform="translate(0,-952.36218)"><path d="m 13,970.36219 c -2.7614,0 -4.9999999,2.2385 -4.9999999,5 0,2.76149 2.2385999,5 4.9999999,5 l 74,0 c 2.7614,0 5,-2.23851 5,-5 0,-2.7615 -2.2386,-5 -5,-5 z m 0,26.99998 c -2.7614,0 -4.9999999,2.2385 -4.9999999,5.00003 0,2.7615 2.2385999,5 4.9999999,5 l 74,0 c 2.7614,0 5,-2.2385 5,-5 0,-2.76153 -2.2386,-5.00003 -5,-5.00003 z m 0,27.00003 c -2.7614,0 -4.9999999,2.2385 -4.9999999,5 0,2.7615 2.2385999,5 4.9999999,5 l 74,0 c 2.7614,0 5,-2.2385 5,-5 0,-2.7615 -2.2386,-5 -5,-5 z" fill="#000000" fill-opacity="1" stroke="none" marker="none" visibility="visible" display="inline" overflow="visible"></path></g></svg> */}
               <div>
               <h4>EMR</h4>
               <div>
               <span className="icon_menu1"></span>
                    <span className="icon_menu2"></span>
                    <span className="icon_menu3"></span>
                    </div>
                    </div>
               </div>
               <div className='title text-start mt-20px pb-0'>
               <h3>Dr.{this.state.doctorlistview.name}'s Profile</h3>
                </div> 
                
               <div className='d-flex w-100 gap-4 vertical-menu-content-wrapper mt-4 min-vh-100'>
                <ul  className={`${this.state.isActive ? "vertical-menu-list active" : "vertical-menu-list"}`}>

                  <li onClick={() => this.handleClick('userdetail')} className={activetab === 'userdetail' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="5 3 22 26"><path d="M11,29H21a6,6,0,0,0,0-12H11a6,6,0,0,0,0,12Z"/><circle cx="16" cy="9" r="6"/></svg>
                     <span>Demographics</span><span class="tooltip">Demographics</span></li>

             

                  <li onClick={() => this.handleClick('pastappointment')} className={activetab === 'pastappointment' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                  <span>Past Appointments</span><span class="tooltip">Past Appts</span><i>{this.state.pastappointment.length}</i></li>

                    <li onClick={() => this.handleClick('ongoingappts')} className={activetab === 'ongoingappts' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    <span>Ongoing Appointments</span><span class="tooltip">Ongoing Appts</span><i>{this.state.ongoingappts.length}</i></li>

                    <li onClick={() => this.handleClick('futureappts')} className={activetab === 'futureappts' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    <span>Future Appointments</span><span class="tooltip">Future Appts</span><i>{this.state.futureappts.length}</i></li>

                    <li onClick={() => this.handleClick('recommendedlist')} className={activetab === 'recommendedlist' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                   <span>Recommended Products</span> <span class="tooltip">Recommended Products</span><i>{this.state.recommendedlist.length}</i></li>

                   <li onClick={() => this.handleClick('RaiseDisputelist')} className={activetab === 'RaiseDisputelist' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    <span>Raised Disputes</span><span class="tooltip">Raised Disputes</span><i>{this.state.RaiseDisputelist.length}</i></li>

                    <li onClick={() => this.handleClick('rating')} className={activetab === 'rating' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    <span>Ratings</span><span class="tooltip">Ratings</span><i>{this.state.ratinglist.length}</i></li>

                    <li onClick={() => this.handleClick('revenuehistory')} className={activetab === 'revenuehistory' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    <span>Revenue Histories</span><span class="tooltip">Revenue Histories</span><i>{this.state.revenuelist.length}</i></li>

                     {/* <li onClick={() => this.handleClick('medical_list')} className={activetab === 'medical_list' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="77.38 31.95 345.25 436.1"><path clipRule="evenodd" d="M113.718,31.949c-20.079,0-36.343,16.264-36.343,36.34v363.421  c0,20.078,16.264,36.34,36.343,36.34h272.566c20.079,0,36.34-16.262,36.34-36.34V158.417c0-12.173-4.81-22.982-12.536-30.796  l-82.497-82.319c-8.173-8.265-19.628-13.352-32.164-13.352H113.718z M240.914,95.548c0-9.996,8.177-18.173,18.172-18.173  c9.991,0,18.172,8.177,18.172,18.173v68.14c0,7.542,6.087,13.629,13.626,13.629h68.143c9.99,0,18.172,8.175,18.172,18.17  c0,9.996-8.182,18.171-18.172,18.171h-81.769c-20.079,0-36.344-16.264-36.344-36.341V95.548z" fill="#000000" fillRule="evenodd"></path></svg>
                    Medical History <i>{this.state.medicalHistorylist.length}</i> </li> 

                    <li onClick={() => this.handleClick('medical_condition')} className={activetab === 'medical_condition' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="77.38 31.95 345.25 436.1"><path clipRule="evenodd" d="M113.718,31.949c-20.079,0-36.343,16.264-36.343,36.34v363.421  c0,20.078,16.264,36.34,36.343,36.34h272.566c20.079,0,36.34-16.262,36.34-36.34V158.417c0-12.173-4.81-22.982-12.536-30.796  l-82.497-82.319c-8.173-8.265-19.628-13.352-32.164-13.352H113.718z M240.914,95.548c0-9.996,8.177-18.173,18.172-18.173  c9.991,0,18.172,8.177,18.172,18.173v68.14c0,7.542,6.087,13.629,13.626,13.629h68.143c9.99,0,18.172,8.175,18.172,18.17  c0,9.996-8.182,18.171-18.172,18.171h-81.769c-20.079,0-36.344-16.264-36.344-36.341V95.548z" fill="#000000" fillRule="evenodd"></path></svg>
                    Medical Condition <i>{this.state.medicalCondition.length}</i> </li>  */}
                
                </ul>
                <div className="vertical-menu-content">

                      <div className={activetab === 'userdetail' ?"content-div userdetail active":"content-div userdetail"} id="userdetail">
                      <div className="title text-start">
                      <h3> Demographics</h3>
                      </div>
                      <div className="form-row">
                        <div className="details_left">
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Username<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.doctorlistview.username}</p>
                              </div>
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Name<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.doctorlistview.name}</p>
                              </div>
                            </div>
                           
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Email<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.doctorlistview.email}</p>
                              </div>
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Mobile number<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.doctorlistview.phone}</p>
                              </div>
                              
                            </div>

                            {(this.state.doctorlistview.graduation!=''&&this.state.doctorlistview.graduation!=null)&&<div className="view-details">
                              <div className="view-details-one">
                                  <p>Graduation<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.doctorlistview.graduation}</p>
                              </div>                              
                            </div>}

                            {(this.state.doctorlistview.experience_yr!=''&&this.state.doctorlistview.experience_yr!=null)&&<div className="view-details">
                              <div className="view-details-one">
                                  <p>Experience<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.doctorlistview.experience_yr}Yrs</p>
                              </div>                              
                            </div>}
                           
                            {(this.state.doctorlistview.consultant_fee!=''&&this.state.doctorlistview.consultant_fee!=0&&this.state.doctorlistview.consultant_fee!=null)&&<div className="view-details">
                              <div className="view-details-one">
                                  <p>Consultant Fees<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>${this.state.doctorlistview.consultant_fee}</p>
                              </div>                              
                            </div>}

                            
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Location<span>:</span></p>
                              </div>
                            <div className="view-details-two">
                                  <p>{ this.state.doctorlistview.location}</p>
                              </div>
                            </div>



                        </div>
                      </div>
                      </div>
                      
                      <div className={activetab === 'revenuehistory' ?"content-div revenuehistory active":"content-div revenuehistory"} id="revenuehistory">
                      <div className="title text-start">
                       
                      <h3>Revenue Histories</h3>

                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                           
                              <tr>
                                <th>S.No</th>
                                <th>Earning From</th>
                                <th>ID</th>                              
                                {/* <th>Name</th> */}
                                <th>Earning Amount</th>
                                {/* <th>Settlement</th> */}
                                <th>Earning On</th>
                              </tr>
                            </thead>
                           <tbody> {this.Revenuelist()}<br/>
                            </tbody>
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className={activetab === 'pastappointment' ?"content-div appointments active":"content-div appointments"} id="appointments">
                      <div className="title text-start">
                       
                      {this.state.ShowPrescriptionlist==true? <h3> Appointment Prescriptions</h3>: <h3>Past Appointments</h3>} 

                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                            {this.state.ShowPrescriptionlist==true?
                              <tr>
                              <th>S.No</th>                              
                              <th>Medicine </th>
                              <th>Quantity</th>
                              <th>Price</th>
                            </tr>:
                              <tr>
                                <th>S.No</th>
                                <th>Appt ID</th>
                                <th>Schedule On</th>
                                <th>Reason</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Mobile</th>
                                <th>Status</th>
                                <th>Prescription</th>
                              </tr>}
                            </thead>
                            {this.state.ShowPrescriptionlist==true?<tbody> {this.apptPrescriptionlist()}<br/>
                            </tbody>:
                            <tbody> {this.pastappointment()}<br/>
                            
                            </tbody>}
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>

                      <div className={activetab === 'ongoingappts' ?"content-div appointments active":"content-div appointments"} id="appointments">
                      <div className="title text-start">
                      {this.state.ShowPrescriptionlist==true? <h3> Appointment Prescriptions</h3>: <h3>Ongoing Appointments</h3>}
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                            {this.state.ShowPrescriptionlist==true?
                              <tr>
                              <th>S.No</th>                              
                              <th>Medicine </th>
                              <th>Quantity</th>
                              <th>Price</th>
                            </tr>:<tr>
                                <th>S.No</th>
                                <th>Appt ID</th>
                                <th>Schedule On</th>
                                <th>Reason</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Mobile</th>
                                <th>Status</th>
                                <th>Prescription</th>
                              </tr>}
                            </thead>
                            {this.state.ShowPrescriptionlist==true?<tbody> {this.apptPrescriptionlist()}<br/>
                            </tbody>:
                            <tbody> {this.ongoingappts()}<br/>
                            </tbody>}
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>


                      <div className={activetab === 'futureappts' ?"content-div appointments active":"content-div appointments"} id="appointments">
                      <div className="title text-start">
                     <h3>Future Appointments</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                            
                              <tr>
                                <th>S.No</th>
                                <th>Appt ID</th>
                                <th>Schedule On</th>
                                <th>Reason</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Mobile</th>
                                <th>Status</th>
                                {/* <th>Action</th> */}
                              </tr>
                            </thead>                           
                            <tbody> {this.futureappts()}<br/>
                            </tbody>
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>

                      <div className={activetab === 'recommendedlist' ?"content-div recommendedlist active":"content-div recommendedlist"} id="recommendedlist">
                      <div className="title text-start">
                     <h3>Recommended Products</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                            
                           
                                {this.state.ShowRecommendedProduct==true?
                                <tr>
                                <th>S.No</th>
                                <th>Name </th>
                                <th>Category </th>
                                <th>Image </th>
                                <th>Single Price </th>
                                <th>Qty</th>                              
                                <th>Total Amount</th>
                                </tr>
                                :
                                <tr>
                                <th>S.No</th>
                                <th>User </th>
                                <th>Products </th>
                                <th>Amount </th>
                                <th>Payment Paid </th>
                                <th>Recommended On </th>                              
                                <th>Action</th>
                              </tr>}
                            </thead>                           
                            <tbody> {this.state.ShowRecommendedProduct==true? this.recommendedProductlist():this.recommendedlist()}<br/>
                            </tbody>
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>
                      

                       <div className={activetab === 'RaiseDisputelist' ?"content-div RaiseDisputelist active":"content-div RaiseDisputelist"} id="RaiseDisputelist">
                      <div className="title text-start">
                        <h3>Raised Disputes </h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Dispute</th>
                                <th>Appt ID</th>
                                <th>User</th>
                                <th>Comment</th>
                                {/* <th>Status</th> */}
                                <th>Uploaded On</th>
                              </tr>
                            </thead>
                            <tbody> {this.RaiseDisputelist()}<br/>
                            
                            </tbody>
                          </Table>
                         
                        </div>
                      </div>

                      <div className={activetab === 'rating' ?"content-div RaiseDisputelist active":"content-div rating"} id="rating">
                      <div className="title text-start">
                        <h3> Ratings</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                               
                                <th>Appt ID</th>
                                <th>User</th>
                                <th>Rating</th>
                                <th>Comment</th>
                                <th>Uploaded On</th>
                              </tr>
                            </thead>
                            <tbody> {this.Ratinglist()}<br/>
                            
                            </tbody>
                          </Table>
                         
                        </div>
                      </div>

                      {/*<div className={activetab === 'medical_list' ?"content-div medical_list active":"content-div medical_list"} id="medical_list">
                      <div className="title text-start">
                        <h3>Medical History</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>File</th>
                                <th>Uploaded On</th>
                              </tr>
                            </thead>
                            <tbody> {this.medicalHistory()}<br/>
                            
                            </tbody>
                          </Table>
                         
                        </div>
                      </div>

                      <div className={activetab === 'medical_condition' ?"content-div medical_condition active":"content-div medical_condition"} id="medical_condition">
                      <div className="title text-start">
                        <h3>Medical Condition</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Medical Condition</th>
                                <th>Uploaded On</th>
                              </tr>
                            </thead>
                            <tbody> {this.medicalCondition()}<br/>                            
                            </tbody>
                          </Table>                         
                        </div>
                      </div> */}
                   
                </div>
              </div>
             
                  
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    doctorlistview: state.doctorlistview
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewDoctorList: (userid) =>{
       dispatch({ type: GET_VIEWDOCTORLIST,userid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(ViewEMR));